@import '../../scss/common/variables';

.add-button {
    background-color: $white;
    border-radius: 50%;
    border: none;
    bottom: 20px;
    box-sizing: border-box;
    box-shadow: 0 0 3px $light-gray;
    cursor: pointer;
    height: 50px;
    position: fixed;
    right: 20px;
    transition: all 0.1s linear;
    width:50px;
    .add-button-icon {
        color: $green;
        font-size: 18px;
        left: 16px; 
        position: absolute;
        top: 16px;
        transition: all 0.2s linear;
        &.fa-plus {
            left: 17px;
        }
    }
    &:hover{
        background-color: $lighter-gray;
        .add-button-icon{
            color: lighten($green, 10);
            font-size: 22px;
            top: 14px;
            left: 15px;
        }
    }
    &:focus {
        outline: none;
    }
    &:active {
        box-shadow: inset 0 0 3px $light-gray;
        .fa-plus {
            font-size: 21px;
        }
    }
    &.clicked {
        .add-button-icon {
            animation: spin 0.3s 1 linear;
            @keyframes spin {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(-360deg);
                }
            }
        }
    }
}