.flip-card-outer {
    background-color: transparent;
    perspective: 3000px;
    .flip-card-inner {
        height: 100%;
        position: relative;
        text-align: center;
        transition: transform 0.8s, height 0.8s;
        transform-style: preserve-3d;
        width: 100%;
        &.flipped {
            transform: rotateY(180deg);
        }
    }
    .flip-card-front, .flip-card-back {
        position: absolute;
        width: 100%;
        height: auto;
        backface-visibility: hidden;
    }
    .flip-card-back {
        transform: rotateY(180deg);
    }
}