@import '../../scss/common/variables';

@mixin activeIcon () {
    animation: spin 0.7s 1 linear;
    font-size: 22px;
}

.save-button {
    background-color: $green;
    border: none;
    border-radius: 3px;
    box-shadow: 0 0 3px $light-gray;
    color: $white;
    font-family: $font-family;
    font-size: 18px;
    height: 40px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s linear;
    width: 50%;
    &.saved {
        border-radius: 50%;
        width: 40px;
        .save-button-text {
            opacity: 0;
        }
        .fa-check, .fa-times{
            transition: all 0.3s linear;
            transition-delay: 0.3s;
        }
        .fa-check {
            @include activeIcon();
            left: 9px;
            top: 8px;
        }
        &.error {
            background-color: $red;
            .fa-check {
                font-size: 0;
            }
            .fa-times {
                @include activeIcon();
                left: 12px;
                top: 9px;
            }

        }
        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(-720deg);
            }
        }
    }
    &:active{
        box-shadow: inset 0 0 5px $light-gray;
    }
    &:focus {
        outline: none;
    }
    .save-button-progress {
        background-color: darken($green, 20);
        height: 100%;
        left: 0;
        opacity: 0.4;
        position: absolute;
        top: 0;
        width: 0;
        &.full {
            transition: width 2s linear, background-color 0.5s linear;
            width: 100%;
            &.error {
                background-color: $red;
            }
        }
    }
    .save-button-text{ 
        transition: opacity 0.2s linear;
    }
    .fa-check, .fa-times {
        font-size: 0;
        position: absolute;
        top: 20px;
        left: 20px;
    }
}