@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{font-family:'Lato', sans-serif}.App{align-items:flex-start;box-sizing:border-box;display:flex;flex-direction:row;min-height:100vh}#main{box-sizing:border-box;display:flex;flex-direction:column;margin-left:220px;width:calc(100% - 220px)}#main-content{background-color:#E8EFF5;min-height:calc(100vh - 72px);padding:30px}#main-content h1{margin:0}

.nav-item{border:0px solid #34C692;-webkit-transition:all 0.2s linear;transition:all 0.2s linear}.nav-item a{color:#7F7F7F;display:inline-block;height:100%;text-decoration:none;text-transform:capitalize;-webkit-transition:all 0.2s linear;transition:all 0.2s linear;width:100%;padding:15px 15px 15px 30px;height:100%}.nav-item:hover{border-color:#82debd;border-left-width:5px}.nav-item:hover a{padding-left:25px;color:#82debd}.nav-item.selected{border-color:#34C692 !important;border-left-width:5px}.nav-item.selected a{padding-left:25px;color:#34C692 !important}

.nav-search{background-color:#29333F;border:none;box-sizing:border-box;color:#7F7F7F;font-family:'Lato', sans-serif;height:40px;margin-bottom:20px;padding-left:60px;width:100%}.nav-search:focus{outline:none}.nav-search-container{position:relative}.search-icon{color:#7F7F7F;position:absolute;top:11px;left:30px}

#main-nav{box-sizing:border-box;background-color:#313D4B;width:220px;position:fixed;height:100vh}#main-nav-header{color:#FFFEFF;text-align:center;text-transform:uppercase;margin:25px 0}#main-nav-header h1{font-size:18px;margin:0}

.card{background:#FFFEFF;border-radius:3px;box-sizing:border-box;box-shadow:0 0 3px #7F7F7F;overflow:hidden}.card .card-header{background:#F4F4F4;padding:15px;height:75px;box-sizing:border-box;display:flex;align-items:center}.card .card-header h1{color:#313D4B}.card .card-content{padding:15px}.card.halfwidth{width:50%}.card.fullwidth{width:100%}

.table{border-collapse:collapse;width:100%}.table thead{border-top:1px solid #E8EFF5;border-left:1px solid #E8EFF5;border-right:1px solid #E8EFF5;box-shadow:0 5px 5px #E8EFF5}.table thead th{color:#34C692;font-weight:400;line-height:1.4;background-color:transparent;padding-top:18px;padding-bottom:18px;text-transform:capitalize}.table tbody tr{border-bottom:1px solid #E8EFF5}.table tbody tr td{background-color:transparent;font-weight:300;line-height:1.4;padding-top:18px;padding-bottom:18px;text-align:center}.table tbody tr td button{background:transparent;border:none;cursor:pointer;height:30px;width:30px}.table tbody tr td button .edit-icon,.table tbody tr td button .delete-icon{-webkit-transition:all 0.2s linear;transition:all 0.2s linear}.table tbody tr td button .edit-icon.clicked,.table tbody tr td button .delete-icon.clicked{-webkit-animation:iconAnimation 0.3s linear 1;animation:iconAnimation 0.3s linear 1}.table tbody tr td button .edit-icon{color:#34C692}.table tbody tr td button .delete-icon{color:#D6425C}.table tbody tr td button:hover .edit-icon,.table tbody tr td button:hover .delete-icon{font-size:19px}@-webkit-keyframes iconAnimation{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}25%{-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}50%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}75%{-webkit-transform:rotate(70deg);transform:rotate(70deg)}}@keyframes iconAnimation{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}25%{-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}50%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}75%{-webkit-transform:rotate(70deg);transform:rotate(70deg)}}.table tbody tr td button:focus{outline:none}.table tbody tr:last-child{border:none}

.add-button{background-color:#FFFEFF;border-radius:50%;border:none;bottom:20px;box-sizing:border-box;box-shadow:0 0 3px #7F7F7F;cursor:pointer;height:50px;position:fixed;right:20px;-webkit-transition:all 0.1s linear;transition:all 0.1s linear;width:50px}.add-button .add-button-icon{color:#34C692;font-size:18px;left:16px;position:absolute;top:16px;-webkit-transition:all 0.2s linear;transition:all 0.2s linear}.add-button .add-button-icon.fa-plus{left:17px}.add-button:hover{background-color:#E8EFF5}.add-button:hover .add-button-icon{color:#59d4a8;font-size:22px;top:14px;left:15px}.add-button:focus{outline:none}.add-button:active{box-shadow:inset 0 0 3px #7F7F7F}.add-button:active .fa-plus{font-size:21px}.add-button.clicked .add-button-icon{-webkit-animation:spin 0.3s 1 linear;animation:spin 0.3s 1 linear}@-webkit-keyframes spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(-360deg);transform:rotate(-360deg)}}@keyframes spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(-360deg);transform:rotate(-360deg)}}

.flip-card-outer{background-color:transparent;-webkit-perspective:3000px;perspective:3000px}.flip-card-outer .flip-card-inner{height:100%;position:relative;text-align:center;-webkit-transition:height 0.8s, -webkit-transform 0.8s;transition:height 0.8s, -webkit-transform 0.8s;transition:transform 0.8s, height 0.8s;transition:transform 0.8s, height 0.8s, -webkit-transform 0.8s;-webkit-transform-style:preserve-3d;transform-style:preserve-3d;width:100%}.flip-card-outer .flip-card-inner.flipped{-webkit-transform:rotateY(180deg);transform:rotateY(180deg)}.flip-card-outer .flip-card-front,.flip-card-outer .flip-card-back{position:absolute;width:100%;height:auto;-webkit-backface-visibility:hidden;backface-visibility:hidden}.flip-card-outer .flip-card-back{-webkit-transform:rotateY(180deg);transform:rotateY(180deg)}.container{max-width:1200px;margin:0 auto}

.input-container{box-sizing:border-box;margin:40px auto 40px;position:relative;text-align:center;width:50%}.input-container:first-child{margin-top:20px}.input-container .input-label{color:#7F7F7F;font-family:"Lato",sans-serif;left:30px;position:absolute;top:10px;-webkit-transition:all 0.3s linear;transition:all 0.3s linear;z-index:10}.input-container .input-label.top{color:#299e74;left:10px;top:-25px}.input-container .input{border:none;border-bottom:1px solid #7F7F7F;box-sizing:border-box;font-family:"Lato",sans-serif;font-size:18px;height:40px;padding-left:30px;width:100%}.input-container .input+.border-focus{border-bottom:3px solid #34C692;bottom:0;content:' ';display:block;left:0;position:absolute;-webkit-transition:all 0.5s linear;transition:all 0.5s linear;width:0}.input-container .input:focus{outline:none}.input-container .input:focus+.border-focus{width:100%}

.save-button{background-color:#34C692;border:none;border-radius:3px;box-shadow:0 0 3px #7F7F7F;color:#FFFEFF;font-family:"Lato",sans-serif;font-size:18px;height:40px;overflow:hidden;position:relative;-webkit-transition:all 0.3s linear;transition:all 0.3s linear;width:50%}.save-button.saved{border-radius:50%;width:40px}.save-button.saved .save-button-text{opacity:0}.save-button.saved .fa-check,.save-button.saved .fa-times{-webkit-transition:all 0.3s linear;transition:all 0.3s linear;-webkit-transition-delay:0.3s;transition-delay:0.3s}.save-button.saved .fa-check{-webkit-animation:spin 0.7s 1 linear;animation:spin 0.7s 1 linear;font-size:22px;left:9px;top:8px}.save-button.saved.error{background-color:#D6425C}.save-button.saved.error .fa-check{font-size:0}.save-button.saved.error .fa-times{-webkit-animation:spin 0.7s 1 linear;animation:spin 0.7s 1 linear;font-size:22px;left:12px;top:9px}@-webkit-keyframes spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(-720deg);transform:rotate(-720deg)}}@keyframes spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(-720deg);transform:rotate(-720deg)}}.save-button:active{box-shadow:inset 0 0 5px #7F7F7F}.save-button:focus{outline:none}.save-button .save-button-progress{background-color:#1f7556;height:100%;left:0;opacity:0.4;position:absolute;top:0;width:0}.save-button .save-button-progress.full{-webkit-transition:width 2s linear, background-color 0.5s linear;transition:width 2s linear, background-color 0.5s linear;width:100%}.save-button .save-button-progress.full.error{background-color:#D6425C}.save-button .save-button-text{-webkit-transition:opacity 0.2s linear;transition:opacity 0.2s linear}.save-button .fa-check,.save-button .fa-times{font-size:0;position:absolute;top:20px;left:20px}

.message-bar{align-items:center;border-radius:5px;box-sizing:border-box;display:flex;height:40px;justify-content:space-between;margin:0 auto 20px;opacity:1;overflow:hidden;padding:15px 25px;position:relative;-webkit-transition:opacity 0.3s linear;transition:opacity 0.3s linear;width:100%}.message-bar.hide{opacity:0}.message-bar.halfwidth{width:50%}.message-bar.error{background-color:#D6425C;color:#FFFEFF}.message-bar:before{background-color:#922034;content:' ';display:block;left:0;height:40px;top:0;position:absolute;width:10px}.message-bar .fa-times{cursor:pointer}

#main-header{align-items:center;box-sizing:border-box;box-shadow:0 0 3px #313D4B;display:flex;height:72px;padding:30px}

