$darker-gray: #29333F;
$dark-gray: #313D4B;
$gray: #8E8C91;
$light-gray: #7F7F7F;
$lighter-gray: #E8EFF5;
$white: #FFFEFF;
$green: #34C692;
$red: #D6425C;
$yellow: #EB9804;
$card-header: #F4F4F4;
$font-family: 'Lato', sans-serif;   

