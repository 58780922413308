@import '../../scss/common/variables';

.input-container{
    box-sizing: border-box;
    margin: 40px auto 40px;
    position: relative;
    text-align: center;
    width: 50%;
    &:first-child {
        margin-top: 20px;
    }
    .input-label {
        color: $light-gray;
        font-family: $font-family;
        left: 30px;
        position: absolute;
        top: 10px;
        transition: all 0.3s linear;
        z-index: 10;
        &.top{
            color: darken($green,10);
            left: 10px;
            top: -25px;
        }
    }
    .input {
        border: none;
        border-bottom: 1px solid $light-gray;
        box-sizing: border-box;
        font-family: $font-family;
        font-size: 18px;
        height: 40px;
        padding-left: 30px;
        width: 100%;
        & + .border-focus{
            border-bottom: 3px solid $green;
            bottom: 0;
            content: ' ';
            display: block;
            left: 0;
            position: absolute;
            transition: all 0.5s linear;
            width: 0;
        }
        &:focus {
            outline: none;
            & + .border-focus {
                width: 100%;
            }
        }
    }
}